var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CurriculumDetail"},[_c('div',{staticClass:"contianer-left"},[_c('div',{staticClass:"video-container",attrs:{"id":"video-container"},on:{"mouseover":_vm.videoHover,"mouseout":_vm.videoOut}},[_c('video',{staticClass:"video",attrs:{"src":_vm.videoObj.link,"id":"video","preload":"load","controls":_vm.videoObj.status == 1 ? 'controls' : false,"poster":require("@/assets/timg.jpeg"),"controlslist":"nodownload","disablePictureInPicture":""},on:{"dblclick":_vm.videoContro,"contextmenu":function($event){$event.preventDefault();}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlay && _vm.videoObj.type == 0),expression:"showPlay && videoObj.type == 0"}],staticClass:"videoMask",on:{"dblclick":_vm.videoContro}},[_c('img',{staticClass:"playImg click",style:({ width: _vm.isPlayEnd ? '99px' : '94px' }),attrs:{"src":_vm.isPlayEnd
              ? require('@/assets/cxbf.png')
              : require('@/assets/videoPlay.png'),"alt":""},on:{"click":_vm.videoPlay}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlayEnd),expression:"isPlayEnd"}],staticClass:"mt-10"},[_vm._v("重新播放")])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVideoControls && _vm.videoObj.status == 0),expression:"showVideoControls && videoObj.status == 0"}],staticClass:"video-controls"},[_c('div',{staticClass:"click",on:{"click":_vm.videoContro}},[_vm._v("⏯")]),_c('el-progress',{staticStyle:{"width":"80%"},attrs:{"percentage":_vm.percentage,"color":"#f56c6c","show-text":false}}),_c('div',[_vm._v(_vm._s(_vm.showTime + " / " + _vm.showAllDuration))]),_c('div',{staticClass:"click",on:{"click":_vm.videoFull}},[_vm._v("全屏")])],1)])],1),_c('div',{staticClass:"likeCollection"},[_c('div',{staticClass:"collection"},[_c('img',{staticClass:"collectionImg click",attrs:{"src":_vm.curObj.collection_status == 1
              ? require('@/assets/sc.png')
              : require('@/assets/wsc.png'),"alt":""},on:{"click":_vm._collection}}),_c('div',{staticClass:"collectionText"},[_vm._v(_vm._s(_vm.curObj.collection_number)+"收藏")])]),_c('div',{staticClass:"zx"}),_c('div',{staticClass:"collection"},[_c('img',{staticClass:"collectionImg click",attrs:{"src":_vm.curObj.like_status == 1
              ? require('@/assets/dz1.png')
              : require('@/assets/wdz.png'),"alt":""},on:{"click":function($event){return _vm._like(0)}}}),_c('div',{staticClass:"collectionText"},[_vm._v(_vm._s(_vm.curObj.like_number)+"点赞")])])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"tagNav"},[_c('div',{staticClass:"tag click",class:{ 'sel-tag': _vm.tagActive == 0 },on:{"click":function($event){return _vm.switchActive(0)}}},[_vm._v(" 课程简介 ")]),_c('div',{staticClass:"tag click",class:{ 'sel-tag': _vm.tagActive == 1 },on:{"click":function($event){return _vm.switchActive(1)}}},[_vm._v(" 评论"+_vm._s(_vm.curObj.estimate_number)+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tagActive == 0),expression:"tagActive == 0"}],staticClass:"descContainer"},[_c('div',{staticClass:"flex-ale cur-name"},[_c('div',{staticClass:"mr-7"},[_vm._v(_vm._s(_vm.curObj.name))]),_c('div',{staticClass:"flex-alc"},_vm._l((_vm.curObj.tag_list),function(item){return _c('div',{key:item.name,staticClass:"cur-tag-type"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"describe"},[_vm._v(" "+_vm._s(_vm.curObj.intro)+" ")]),_c('el-divider'),_c('div',{staticClass:"catalogText"},[_vm._v("课程目录")]),_c('div',{staticClass:"catalogContainer"},_vm._l((_vm.catalogList),function(item,index){return _c('div',{key:'catalog' + index,staticClass:"catalogCard click",on:{"click":function($event){return _vm.swicthVideo(item, index)}}},[_c('div',{staticClass:"flex-alc"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.isPalyCatalog),expression:"index == isPalyCatalog"}],staticClass:"waveSeat",attrs:{"src":require("@/assets/dqbf.png"),"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index != _vm.isPalyCatalog),expression:"index != isPalyCatalog"}],staticClass:"waveSeat"}),_c('div',{staticClass:"title",style:({
                  color: item.status ? '#9D9D9D' : '#000',
                })},[_vm._v(" - 目录No."+_vm._s(index + 1)+" ："+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"flex-alc"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.status),expression:"item.status"}],staticClass:"wancheng-img",attrs:{"src":require("@/assets/wancheng.png"),"alt":""}}),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.catalogType[item.type])+" ")])])])}),0)],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadNext),expression:"loadNext"},{name:"show",rawName:"v-show",value:(_vm.tagActive == 1),expression:"tagActive == 1"}],staticClass:"commentContainer mt-25",style:({ height: _vm.commentList.length ? '800px' : '400px' })},[(!_vm.commentList.length)?_c('div',{staticClass:"mt-52 flex-alc column mb-120"},[_c('div',{staticClass:"no-data mb-26"},[_vm._v("暂时还没有评论呢")]),_c('div',{staticClass:"no-data-line"})]):_vm._e(),_vm._l((_vm.commentList),function(item,index){return _c('div',{key:'comment' + index,staticClass:"commentCard",style:({ 'margin-top': index == 0 ? '0' : '25px' })},[(index != 0)?_c('el-divider'):_vm._e(),_c('div',{staticClass:"infoCard"},[_c('div',{staticClass:"flex-alc"},[_c('img',{staticClass:"head",attrs:{"src":item.qywx_user.avatar ||
                  require('@/assets/defulatAvatar.png'),"alt":""}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.qywx_user.name)+" ")])]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.fmt_created))])]),_c('Score',{attrs:{"isComment":false,"detail":item}}),_c('div',{staticClass:"muchdz"},[_c('img',{staticClass:"smolldz",attrs:{"src":item.has_like == 1
                  ? require('@/assets/ic_pfdz@2x.png')
                  : require('@/assets/dz2.png'),"alt":""},on:{"click":function($event){return _vm._like(1, index)}}}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.like_number))])])],1)})],2)])]),_c('div',{staticClass:"contianer-right pb-40"},[(_vm.teacher.lecturer_format && _vm.teacher.lecturer_format.name)?_c('div',{staticClass:"teacher pb-30"},[_c('div',{staticClass:"text"},[_vm._v("课程讲师")]),_c('el-divider'),_c('img',{staticClass:"rankImg",attrs:{"src":_vm.rankImg[_vm.teacher.lecturer_format.rank],"alt":""}}),_c('div',{staticClass:"teacherInfo"},[_c('img',{staticClass:"head click",attrs:{"src":_vm.teacher.lecturer_format.avatar ||
            require('@/assets/defulatAvatar.png'),"alt":""},on:{"click":function($event){return _vm.toTeacherDetail(_vm.teacher.lecturer_format.id)}}}),_c('div',{staticClass:"flex-alc"},[_c('div',{staticClass:"fraction"},[_vm._v(" "+_vm._s(Number(_vm.teacher.lecturer_format.star).toFixed(1))+" ")]),_c('el-rate',{attrs:{"value":_vm.teacher.lecturer_format.star / 2,"disabled":true,"text-color":"#FF7500","max":5}})],1),_c('div',{staticClass:"teacherName click",on:{"click":function($event){return _vm.toTeacherDetail(_vm.teacher.lecturer_format.id)}}},[_vm._v(" "+_vm._s(_vm.teacher.lecturer_format.name)+" ")])]),_c('div',{staticClass:"number"},[_vm._v(" 课程数量："+_vm._s(_vm.teacher.lecturer_format.cur_number)+" ")]),_c('div',{staticClass:"number"},[_vm._v(" 学习次数："+_vm._s(_vm.teacher.lecturer_format.study_number)+" ")])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.recommendCur.length),expression:"recommendCur.length"}],staticClass:"teacher mt-13 pb-19"},[_c('div',{staticClass:"text"},[_vm._v("课程推荐")]),_c('el-divider'),_c('div',{staticClass:"card"},_vm._l((_vm.recommendCur),function(item,index){return _c('Curriculum',{key:'cur' + index,staticClass:"mb-20",attrs:{"curriculum":item,"showBorder":true,"border":6,"imgSize":218}})}),1)],1)]),_c('el-dialog',{staticClass:"pos-fix",attrs:{"visible":_vm.isRate,"width":"648px","show-close":false,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.isRate=$event}}},[_c('div',{staticClass:"rate-container"},[_c('div',{staticClass:"title"},[_vm._v("课程已结束！请评分！")]),_c('el-divider'),_c('div',{staticClass:"rate-type"},[_c('Score',{ref:"getScore",attrs:{"detail":{},"isComment":true,"disabled":false}})],1),_c('div',{staticClass:"button click",on:{"click":_vm.submit}},[_vm._v("提交")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }