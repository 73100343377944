<!-- 
    评分 disabled 是否只读 默认true isComment 是否为提交评论
    <Score :disabled='false'></Score>
-->
<template>
    <div class="Score">
        <div v-for="(item, index) in scoreList" :key="'scorelist' + index">
            <div class="rate-title">{{ item.title }}</div>
            <div
                v-for="(it, index) in item.list"
                :key="'score' + index"
                class="rateType"
                :class="{ between: isComment }"
            >
                <div class="text">{{ it.name }}</div>
                <el-rate
                    v-model="it.rate"
                    :disabled="disabled"
                    text-color="#FF7500"
                    @change="(e) => (it.rate = e)"
                    :max="5"
                    v-if="isComment"
                >
                </el-rate>
                <el-rate
                    :value="it.rate / 2"
                    :disabled="disabled"
                    text-color="#FF7500"
                    @change="(e) => (it.rate = e)"
                    :max="5"
                    v-if="!isComment"
                >
                </el-rate>
            </div>
            <div v-if="!isComment" class="desc">{{ item.desc }}</div>
            <div v-if="isComment">
                <textarea
                    name="text"
                    v-model="item.desc"
                    placeholder="说点什么吧..."
                    class="textarea"
                ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Score",
        props: {
            disabled: {
                type: Boolean,
                default: true,
            },
            isComment: {
                type: Boolean,
                default: true,
            },
            detail: {
                type: Object,
            },
        },
        data() {
            return {
                scoreList: [],
            };
        },
        mounted() {
            this.scoreList = [
                {
                    title: "对课程评分",
                    type: "cur_content",
                    list: [
                        {
                            type: "cur_quality",
                            name: "总体质量",
                            rate: this.detail.cur_quality || 0,
                        },
                        {
                            type: "cur_help",
                            name: "启发与帮助",
                            rate: this.detail.cur_help || 0,
                        },
                        {
                            type: "cur_app",
                            name: "结合与应用",
                            rate: this.detail.cur_app || 0,
                        },
                        {
                            type: "cur_understand",
                            name: "易于理解，深度适用",
                            rate: this.detail.cur_understand || 0,
                        },
                    ],
                    desc: this.detail.cur_content || "",
                },
                {
                    title: "对讲师评分",
                    type: "lec_content",
                    list: [
                        {
                            type: "lec_familiarity",
                            name: "对内容的熟悉程度",
                            rate: this.detail.lec_familiarity || 0,
                        },
                        {
                            type: "lec_skill",
                            name: "表达能力和技巧",
                            rate: this.detail.lec_skill || 0,
                        },
                        {
                            type: "lec_atmosphere",
                            name: "课堂氛围",
                            rate: this.detail.lec_atmosphere || 0,
                        },
                        {
                            type: "lec_understand",
                            name: "易于理解，深度适用",
                            rate: this.detail.lec_understand || 0,
                        },
                    ],
                    desc: this.detail.lec_content || "",
                },
                {
                    title: "对硬件评分",
                    type: "lha_content",
                    list: [
                        {
                            type: "lha_understand",
                            name: "准备工作充分",
                            rate: this.detail.lha_understand || 0,
                        },
                        {
                            type: "lha_satisfaction",
                            name: "培训整体满意度",
                            rate: this.detail.lha_satisfaction || 0,
                        },
                    ],
                    desc: this.detail.lha_content || "",
                },
            ];
        },
    };
</script>

<style lang="less" scoped>
.Score {
    .rate-title {
        font-size: 18px;
        font-weight: 500;
        color: #4477bc;
        margin-bottom: 14px;
        margin-top: 13px;
    }
    .rateType {
        display: flex;
        margin-bottom: 3px;
        align-items: center;
        .text {
            font-size: 14px;
            font-weight: 400;
            color: #929292;
            width: 180px;
        }
    }
    .desc {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        margin-top: 10px;
    }
    .textarea {
        width: 100%;
        height: 56px;
        padding: 6px 8px;
        border: 1px solid #d5d5d5;
        outline: none;
    }
    /deep/.el-rate {
        height: auto;
        .el-rate__icon {
            font-size: 27px;
            margin-right: 0;
        }
    }
}
</style>
