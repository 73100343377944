<template>
  <div class="CurriculumDetail">
    <div class="contianer-left">
      <div
        class="video-container"
        id="video-container"
        @mouseover="videoHover"
        @mouseout="videoOut"
      >
        <video
          :src="videoObj.link"
          id="video"
          preload="load"
          :controls="videoObj.status == 1 ? 'controls' : false"
          class="video"
          poster="@/assets/timg.jpeg"
          controlslist="nodownload"
          disablePictureInPicture
          @dblclick="videoContro"
          @contextmenu.prevent=""
        ></video>
        <div
          class="videoMask"
          @dblclick="videoContro"
          v-show="showPlay && videoObj.type == 0"
        >
          <img
            :src="
              isPlayEnd
                ? require('@/assets/cxbf.png')
                : require('@/assets/videoPlay.png')
            "
            class="playImg click"
            :style="{ width: isPlayEnd ? '99px' : '94px' }"
            @click="videoPlay"
            alt=""
          />
          <div class="mt-10" v-show="isPlayEnd">重新播放</div>
        </div>
        <transition name="fade" mode="out-in">
          <div
            class="video-controls"
            v-show="showVideoControls && videoObj.status == 0"
          >
            <div class="click" @click="videoContro">⏯</div>
            <el-progress
              style="width: 80%"
              :percentage="percentage"
              color="#f56c6c"
              :show-text="false"
            ></el-progress>
            <div>{{ showTime + " / " + showAllDuration }}</div>
            <div class="click" @click="videoFull">全屏</div>
          </div>
        </transition>
      </div>

      <div class="likeCollection">
        <div class="collection">
          <img
            class="collectionImg click"
            @click="_collection"
            :src="
              curObj.collection_status == 1
                ? require('@/assets/sc.png')
                : require('@/assets/wsc.png')
            "
            alt=""
          />
          <div class="collectionText">{{ curObj.collection_number }}收藏</div>
        </div>
        <div class="zx"></div>
        <div class="collection">
          <img
            class="collectionImg click"
            @click="_like(0)"
            :src="
              curObj.like_status == 1
                ? require('@/assets/dz1.png')
                : require('@/assets/wdz.png')
            "
            alt=""
          />
          <div class="collectionText">{{ curObj.like_number }}点赞</div>
        </div>
      </div>

      <div class="detail">
        <div class="tagNav">
          <div
            class="tag click"
            @click="switchActive(0)"
            :class="{ 'sel-tag': tagActive == 0 }"
          >
            课程简介
          </div>
          <div
            class="tag click"
            @click="switchActive(1)"
            :class="{ 'sel-tag': tagActive == 1 }"
          >
            评论{{ curObj.estimate_number }}
          </div>
        </div>
        <!-- 课程简介 -->
        <div class="descContainer" v-show="tagActive == 0">
          <div class="flex-ale cur-name">
            <div class="mr-7">{{ curObj.name }}</div>
            <div class="flex-alc">
              <div
                class="cur-tag-type"
                v-for="item in curObj.tag_list"
                :key="item.name"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="describe">
            {{ curObj.intro }}
          </div>
          <el-divider></el-divider>
          <div class="catalogText">课程目录</div>
          <div class="catalogContainer">
            <div
              class="catalogCard click"
              v-for="(item, index) in catalogList"
              :key="'catalog' + index"
              @click="swicthVideo(item, index)"
            >
              <div class="flex-alc">
                <img
                  src="@/assets/dqbf.png"
                  class="waveSeat"
                  alt=""
                  v-show="index == isPalyCatalog"
                />
                <div class="waveSeat" v-show="index != isPalyCatalog"></div>
                <div
                  class="title"
                  :style="{
                    color: item.status ? '#9D9D9D' : '#000',
                  }"
                >
                  - 目录No.{{ index + 1 }} ：{{ item.name }}
                </div>
              </div>
              <div class="flex-alc">
                <img
                  src="@/assets/wancheng.png"
                  class="wancheng-img"
                  alt=""
                  v-show="item.status"
                />
                <div class="type">
                  {{ catalogType[item.type] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 评论 -->
        <div
          class="commentContainer mt-25"
          :style="{ height: commentList.length ? '800px' : '400px' }"
          v-infinite-scroll="loadNext"
          v-show="tagActive == 1"
        >
          <div v-if="!commentList.length" class="mt-52 flex-alc column mb-120">
            <div class="no-data mb-26">暂时还没有评论呢</div>
            <div class="no-data-line"></div>
          </div>
          <div
            class="commentCard"
            :style="{ 'margin-top': index == 0 ? '0' : '25px' }"
            v-for="(item, index) in commentList"
            :key="'comment' + index"
          >
            <el-divider v-if="index != 0"></el-divider>
            <div class="infoCard">
              <div class="flex-alc">
                <img
                  class="head"
                  :src="
                    item.qywx_user.avatar ||
                    require('@/assets/defulatAvatar.png')
                  "
                  alt=""
                />
                <div class="name">
                  {{ item.qywx_user.name }}
                </div>
              </div>
              <div class="time">{{ item.fmt_created }}</div>
            </div>
            <Score :isComment="false" :detail="item"></Score>
            <div class="muchdz">
              <img
                :src="
                  item.has_like == 1
                    ? require('@/assets/ic_pfdz@2x.png')
                    : require('@/assets/dz2.png')
                "
                class="smolldz"
                alt=""
                @click="_like(1, index)"
              />
              <div class="text">{{ item.like_number }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contianer-right pb-40">
      <div
        class="teacher pb-30"
        v-if="teacher.lecturer_format && teacher.lecturer_format.name"
      >
        <div class="text">课程讲师</div>
        <el-divider></el-divider>
        <img
          :src="rankImg[teacher.lecturer_format.rank]"
          class="rankImg"
          alt=""
        />
        <div class="teacherInfo">
          <img
            class="head click"
            :src="
              teacher.lecturer_format.avatar ||
              require('@/assets/defulatAvatar.png')
            "
            alt=""
            @click="toTeacherDetail(teacher.lecturer_format.id)"
          />
          <div class="flex-alc">
            <div class="fraction">
              {{ Number(teacher.lecturer_format.star).toFixed(1) }}
            </div>
            <el-rate
              :value="teacher.lecturer_format.star / 2"
              :disabled="true"
              text-color="#FF7500"
              :max="5"
            ></el-rate>
          </div>
          <div
            class="teacherName click"
            @click="toTeacherDetail(teacher.lecturer_format.id)"
          >
            {{ teacher.lecturer_format.name }}
          </div>
        </div>
        <div class="number">
          课程数量：{{ teacher.lecturer_format.cur_number }}
        </div>
        <div class="number">
          学习次数：{{ teacher.lecturer_format.study_number }}
        </div>
      </div>

      <div class="teacher mt-13 pb-19" v-show="recommendCur.length">
        <div class="text">课程推荐</div>
        <el-divider></el-divider>
        <div class="card">
          <Curriculum
            v-for="(item, index) in recommendCur"
            :key="'cur' + index"
            :curriculum="item"
            class="mb-20"
            :showBorder="true"
            :border="6"
            :imgSize="218"
          ></Curriculum>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="isRate"
      class="pos-fix"
      width="648px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="rate-container">
        <div class="title">课程已结束！请评分！</div>
        <el-divider></el-divider>
        <div class="rate-type">
          <Score
            ref="getScore"
            :detail="{}"
            :isComment="true"
            :disabled="false"
          ></Score>
        </div>
        <div class="button click" @click="submit">提交</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Score from "@/components/Score.vue";
import Curriculum from "@/components/Curriculum.vue";
export default {
  name: "CurriculumDetail",
  components: {
    Score,
    Curriculum,
  },
  data() {
    return {
      video: null, //视频对象
      sym: 0, //上一秒播放时间
      time: 0, //当前播放时间
      showPlay: true, //播放按钮展示
      isRate: false, //播放结束评分
      rateObj: "", //评分小节对象

      id: 0, //课程id
      videoObj: "", //当前选中的课程小节对象
      showAllDuration: "0:00", //展示的总时长
      showTime: "0:00", //展示当前播放时间
      allDuration: 0, //课程小节总时长
      catalogType: ["课程学习", "课程学习", "测评答题", "课程评分"], //课程小节类型
      submitVideo: null, //课程进度定时器
      videoProgess: null, //视频进度定时器
      catalogList: [], //课程小节列表
      page: 1, //当前页
      isPlayEnd: false, //是否播放结束
      has_next: false, //是否有下一页
      tagActive: 0, //0 显示简介 1显示评论
      isPalyCatalog: -1, //当前播放目录
      curObj: "", //课程详情
      teacher: "", //课程讲师
      recommendCur: [], //推荐课程
      commentList: [], //评论列表
      rankImg: [
        require("@/assets/one.png"),
        require("@/assets/two.png"),
        require("@/assets/three.png"),
        require("@/assets/three.png"),
        require("@/assets/three.png"),
        // require("@/assets/ic_qt@2x.png"),
        // require("@/assets/ic_by@2x.png"),
        // require("@/assets/ic_hj@2x.png"),
        // require("@/assets/ic_zs@2x.png"),
        // require("@/assets/ic_wp@2x.png"),
      ], //讲师段位

      percentage: 0, //播放进度
      showVideoControls: false, //视频控件显示
    };
  },
  watch: {
    //路由参数改变  刷新页面
    async $route() {
      await this.getRefresh();
    },
  },

  async mounted() {
    await this.getRefresh();
    document.addEventListener("fullscreenchange", this.exitHandler);
    document.addEventListener("webkitfullscreenchange", this.exitHandler);
    document.addEventListener("mozfullscreenchange", this.exitHandler);
    document.addEventListener("MSFullscreenChange", this.exitHandler);
  },

  destroyed() {
    //清除定时器
    clearInterval(this.submitVideo);
    clearInterval(this.videoProgess);
    this.submitVideo = null;
    this.videoProgess = null;
  },

  methods: {
    exitHandler() {
      console.log("退出全屏");
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        //兼容ie浏览器
        let ele = document.getElementById("video-container");
        ele.style.height = "568px";
      }
    },
    //讲师详情
    toTeacherDetail(e) {
      console.log(e);
      this.$router.push({
        path: "teacherDetail",
        query: { id: e },
      });
    },
    //进入全屏
    FullScreen() {
      let ele = document.getElementById("video-container");
      try {
        if (ele.requestFullscreen) {
          ele.requestFullscreen();
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen();
        } else if (ele.msRequestFullscreen) {
          //兼容ie浏览器
          ele.style.height = "95vh";
          ele.msRequestFullscreen();
        } else {
          ele.fullScreen();
        }
      } catch (err) {
        console.log("errr", err);
      }
    },
    //全屏播放
    videoFull() {
      this.FullScreen();
      return console.log(
        "document.fullscreenEnabled",
        document.getElementById("video").fullscreenEnabled
      );
    },
    //视频Out
    videoOut() {
      this.showVideoControls = false;
    },
    //视频hover
    videoHover() {
      this.showVideoControls = true;
    },
    //刷新页面数据
    async getRefresh() {
      this.id = this.$route.query.id;
      await this.$https
        .get("/api/cur/list", {
          cur_id: this.id,
          limit: 3,
        })
        .then((res) => {
          this.recommendCur = res.data.list;
        });
      //获取课程详情
      await this.getCurDetail();
      //获取评论列表
      await this.getComment();
      //获取课程小节
      await this.getCurCatalog();

      //展示视频时间
      document.getElementById("video").oncanplay = () => {
        let playTime = this.videoObj.progress;
        let min = (playTime / 60).toString().split(".")[0];
        let sec = (playTime % 60).toString().split(".")[0];
        if (sec < 10) {
          sec = "0" + sec;
        }
        this.showTime = min + ":" + sec;
        let allTime = document.getElementById("video").duration || 0;
        let time = (+allTime % 60).toString().split(".")[0];
        if (time < 10) {
          time = "0" + time;
        }
        this.showAllDuration =
          (+allTime / 60).toString().split(".")[0] + ":" + time;
      };

      //监听视频播放结束
      document.getElementById("video").addEventListener(
        "ended",
        async () => {
          //显示播放按钮
          this.showPlay = true;
          console.log("播放结束");
          clearInterval(this.submitVideo);
          clearInterval(this.videoProgess);
          this.submitVideo = null;
          this.videoProgess = null;
          this.$https.post("/api/set/progress", {
            id: this.videoObj.id,
            progress: 0.1,
            status: 1,
          });
          this.isPlayEnd = true;
          this.catalogList[this.isPalyCatalog].status = 1;
          this.catalogList[this.isPalyCatalog].progress = 0;
          this.videoObj.status = 1;
          this.videoObj.progress = 0;
          this.percentage = 100;
          this.showTime = this.showAllDuration;
          let isRate = true, //评分小节之前小节是否完成
            has_rate = false; //是否弹评分窗口

          let id = this.videoObj.id;
          //自动播放下一个视频
          this.catalogList.forEach((i, idx) => {
            if (i.id == id) {
              if (
                idx + 1 < this.catalogList.length &&
                this.catalogList[idx + 1].type == 0
              ) {
                this.swicthVideo(
                  this.catalogList[idx + 1],
                  idx + 1
                );
              }
            }
          });
          //是否弹出评论框
          for (let i = 0;i < this.catalogList.length;i++) {
            if (
              this.catalogList[i].status == 0 &&
              this.catalogList[i].type != 3
            ) {
              isRate = false;
            }
            if (
              isRate &&
              this.catalogList[i].status == 0 &&
              this.catalogList[i].type == 3
            ) {
              this.rateObj = this.catalogList[i];
              has_rate = true;
              break;
            }
          }
          this.isRate = has_rate;
          //判断是否要测评
          if (!isRate) {
            this.goEvaluation();
          }
        },
        false
      );
    },
    //切换简介、评价
    switchActive(e) {
      this.tagActive = e;
      this.page = 1;
      if (e == 1) {
        this.getComment();
      }
    },
    //评论分页
    loadNext() {
      if (!this.has_next) return;
      this.getComment(this.page, 1);
    },
    //切换课程小节
    async swicthVideo(e, idx) {
      if (e.type == 3) {
        if (e.status) {
          return this.$message({
            message: "您已完成该课程评价，谢谢！",
            type: "success",
          });
        } else {
          return this.$message({
            message: "请完成所有课程，再进行评分！",
            type: "warning",
          });
        }
      }
      //视频
      this.isPlayEnd = false;
      this.percentage = 0;
      this.isPalyCatalog = idx;
      if (e.type == 0) {
        document.getElementById("video").pause();
        this.videoObj = this.catalogList[idx];
        this.teacher = this.videoObj;
        clearInterval(this.submitVideo);
        clearInterval(this.videoProgess);
        this.videoProgess = null;
        this.submitVideo = null;
        this.showPlay = true;
        let playTime = this.videoObj.progress;
        let min = (playTime / 60).toString().split(".")[0];
        let sec = (playTime % 60).toString().split(".")[0];
        if (sec < 10) {
          sec = "0" + sec;
        }
        this.showTime = min + ":" + sec;

        let allTime;
        document.getElementById("video").oncanplay = () => {
          allTime = document.getElementById("video").duration || 0;
          let time = (+allTime % 60).toString().split(".")[0];
          if (time < 10) {
            time = "0" + time;
          }
          this.showAllDuration =
            (+allTime / 60).toString().split(".")[0] + ":" + time;
        };
        setTimeout(() => {
          this.videoPlay();
        }, 200);
      }
      //PDF
      if (e.type == 1) {
        clearInterval(this.submitVideo);
        clearInterval(this.videoProgess);
        this.videoProgess = null;
        this.submitVideo = null;
        document.getElementById("video").pause();
        this.teacher = this.catalogList[idx];
        this.showPlay = true;
        window.open(e.link);
        this.$https
          .post("/api/set/progress", {
            id: e.id,
            status: 1,
          })
          .then((res) => {
            let isRate = true,
              has_rate = false;
            this.catalogList.forEach((i) => {
              if (e.id == i.id) {
                i.status = 1;
              }
            });
            for (let i = 0;i < this.catalogList.length;i++) {
              if (
                this.catalogList[i].status == 0 &&
                this.catalogList[i].type != 3
              ) {
                isRate = false;
              }
              if (
                isRate &&
                this.catalogList[i].status == 0 &&
                this.catalogList[i].type == 3
              ) {
                this.rateObj = this.catalogList[i];
                has_rate = true;
                break;
              }
            }
            this.isRate = has_rate;
            if (!isRate) {
              this.goEvaluation();
            }
          });
      }
      //测评
      if (e.type == 2) {
        this.$https
          .get("/api/exam/evaluation/detail", {
            id: e.evaluation_id,
            ifretest: 0,
          })
          .then((res) => {
            if (res.errcode) return this.$message.error(res.msg);
            if (res.data.status == 2) {
              if (!res.data.test_has_next) {
                this.$message.error("该测评次数已达到上限！");
                return;
              }
              return this.$alert(
                "您已完成该测评，是否要重新测评?",
                "提示信息",
                {
                  confirmButtonText: "确定",
                  showCancelButton: true,
                  closeOnPressEscape: true,
                  closeOnClickModal: true,
                  showClose: true,
                }
              )
                .then(() => {
                  this.$router.push({
                    path: "/InTest",
                    query: {
                      test: e.evaluation_id,
                      ifretest: 1,
                    },
                  });
                })
                .catch((action) => { });
            } else {
              clearInterval(this.submitVideo);
              clearInterval(this.videoProgess);
              this.videoProgess = null;
              this.submitVideo = null;
              this.$router.push({
                path: "/InTest",
                query: { test: e.evaluation_id, ifretest: 0 },
              });
            }
          });
      }
    },
    //获取评论列表
    getComment(page = 1, type = 0) {
      this.$https
        .get("/api/cur/score/list", {
          id: this.id,
          page,
          limit: 5,
        })
        .then((res) => {
          if (type) {
            this.commentList = [
              ...this.commentList,
              ...res.data.list,
            ];
          } else {
            this.commentList = res.data.list;
          }
          this.has_next = res.data.has_next;
          this.page++;
        });
    },
    //获取课程小节
    async getCurCatalog() {
      await this.$https
        .get("/api/cur/content/list", { id: this.id })
        .then((res) => {
          if (res.errcode) return;
          this.catalogList = res.data.list;
          for (let i = 0;i < this.catalogList.length;i++) {
            if (!this.catalogList[i].status) {
              if (this.catalogList[i].type == 0) {
                this.videoObj = this.catalogList[i];
              }
              this.isPalyCatalog = i;
              this.teacher = this.catalogList[i];
              break;
            }
          }
          //讲师
          if (!this.teacher) {
            this.isPalyCatalog = 0;
            this.teacher = this.catalogList[0];
          }
          let isRate = true,
            has_rate = false;
          for (let i = 0;i < this.catalogList.length;i++) {
            if (
              this.catalogList[i].status == 0 &&
              this.catalogList[i].type != 3
            ) {
              isRate = false;
            }
            if (
              isRate &&
              this.catalogList[i].status == 0 &&
              this.catalogList[i].type == 3
            ) {
              this.rateObj = this.catalogList[i];
              has_rate = true;
              break;
            }
          }
          this.isRate = has_rate;
          if (!isRate) {
            console.log("2", this.catalogList);
            this.goEvaluation();
          }
        });
    },
    //获取课程详情
    async getCurDetail() {
      await this.$https
        .get("/api/cur/detail", { id: this.id })
        .then((res) => {
          if (res.errcode) return;
          console.log("1");
          this.curObj = res.data.detail;
        });
    },
    //提交评价
    async submit() {
      let scoreList = this.$refs.getScore._data.scoreList,
        data = {};
      scoreList.forEach((i) => {
        data[i.type] = i.desc;
        i.list.forEach((j) => {
          data[j.type] = j.rate * 2;
        });
      });
      data["id"] = this.id;
      let res = await this.$https.post("/api/comment/cur", data);
      if (res.errcode) return this.$message.error(res.msg);
      await this.$https.post("/api/set/progress", {
        id: this.rateObj.id,
        status: 1,
      });
      this.page = 1;
      this.commentList = [];
      this.getComment();
      await this.getCurDetail();
      this.getCurCatalog();
      this.isRate = false;
    },
    //播放视频
    videoPlay() {
      this.showPlay = false;
      this.isPlayEnd = false;
      this.allDuration = document.getElementById("video").duration;
      document.getElementById("video").currentTime =
        this.videoObj.progress || 0;
      this.percentage =
        (this.videoObj.progress / this.allDuration).toFixed(2) * 100;
      document.getElementById("video").play();
      this.videoProgess = setInterval(() => {
        let time = document.getElementById("video").currentTime || 0;
        let allTime = document.getElementById("video").duration || 0;
        let min = (+time % 60).toFixed(2).toString().split(".")[0];
        this.percentage = (time / allTime).toFixed(2) * 100;
        if (min < 10) {
          min = "0" + min;
        }
        this.showTime =
          (+time / 60).toFixed(2).toString().split(".")[0] +
          ":" +
          min;
      }, 1000);
      this.submitVideo = setInterval(() => {
        this.$https
          .post("/api/set/progress", {
            id: this.videoObj.id,
            progress: document
              .getElementById("video")
              .currentTime.toFixed(2),
            status: this.videoObj.status || 0,
          })
          .then((res) => {
            this.catalogList[this.isPalyCatalog].progress =
              res.data.detail.progress;
          });
      }, 6000);
    },
    //双击暂停播放
    videoContro() {
      if (!this.videoObj && this.videoObj.link) return;
      if (document.getElementById("video").paused) {
        this.videoPlay();
        this.isPlayEnd = false;
      } else {
        clearInterval(this.submitVideo);
        clearInterval(this.videoProgess);
        this.videoProgess = null;
        this.submitVideo = null;
        document.getElementById("video").pause();
        this.showPlay = true;
      }
    },
    //收藏
    _collection() {
      this.$https
        .post("/api/cur/collect", { id: this.id })
        .then((res) => {
          this.curObj.collection_status = res.data.status;
          this.curObj.collection_number = res.data.number;
        });
    },
    //点赞
    _like(type = 0, index = 0) {
      this.$https
        .post("/api/cur/like", {
          id: type ? this.commentList[index].id : this.id,
          type,
        })
        .then((res) => {
          if (type) {
            this.commentList[index].has_like = res.data.status;
            this.commentList[index].like_number = res.data.number;
          } else {
            this.curObj.like_status = res.data.status;
            this.curObj.like_number = res.data.number;
          }
        });
    },
    //学习完成前往测评
    goEvaluation() {
      let evaluationId = 0,
        id = 0,
        isEvaluation = true;
      this.catalogList.forEach((i, idx) => {
        if (i.status != 1 && i.type != 2) {
          isEvaluation = false;
        }
      });
      this.catalogList.forEach((i, idx) => {
        if (
          i.type == 2 &&
          i.status != 1 &&
          i.evaluation_id &&
          isEvaluation
        ) {
          evaluationId = i.evaluation_id;
          id = i.id;
        }
      });
      console.log("evaluationId", evaluationId, isEvaluation);
      if (!evaluationId) return console.log("已完成测评");
      this.$alert("课程已学习完毕即将前往测评！", "提示信息", {
        confirmButtonText: "确定",
        showCancelButton: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        showClose: false,
      })
        .then(() => {
          this.$router.push({
            path: "/InTest",
            query: { test: evaluationId, ifretest: 0 },
          });
        })
        .catch((action) => {
          return false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
// video::-webkit-media-controls {
//     display: none !important;
// }
.CurriculumDetail {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  .contianer-left {
    width: 900px;
    .video-container {
      width: 100%;
      height: 568px;
      margin-top: 40px;
      margin-bottom: 27px;
      position: relative;
      .video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border: none;
        outline: none;
      }
      .videoMask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #fff;
        justify-content: center;
        .playImg {
          width: 94px;
          height: 94px;
        }
      }
      .video-controls {
        position: absolute;
        display: flex;
        width: 100%;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
      }
    }
    .likeCollection {
      width: 100%;
      height: 61px;
      background: #ffffff;
      display: flex;
      align-items: center;
      margin-bottom: 27px;
      .collection {
        display: flex;
        align-items: center;
        .collectionImg {
          width: 34px;
          height: 34px;
          margin-right: 17px;
          margin-left: 30px;
        }
        .collectionText {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
      }
      .zx {
        width: 1px;
        height: 34px;
        margin: 0 18px 0 48px;
        border: 1px solid #d5d5d5;
      }
    }
    .detail {
      padding: 15px 20px;
      background: white;
      width: 100%;
      margin-bottom: 30px;
      .tagNav {
        display: flex;
        .tag {
          font-size: 18px;
          font-weight: 500;
          color: #c3c3c3;
          margin-right: 40px;
        }
        .sel-tag {
          color: #000;
        }
      }
      .descContainer {
        margin-bottom: 20px;
        .cur-name {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          margin-top: 25px;
          .cur-tag-type {
            font-size: 12px;
            font-weight: 400;
            color: #f32929;
            padding: 1px 8px;
            margin-left: 10px;
            background: #fcdddd;
            border-radius: 10px;
          }
        }
        .describe {
          font-size: 18px;
          font-weight: 400;
          color: #000000;
          margin-top: 17px;
          line-break: anywhere;
        }
        .el-divider--horizontal {
          margin: 26px 0 15px 0;
        }
        .catalogText {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 16px;
        }
        .catalogContainer {
          .catalogCard {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            background: #f6f6f6;
            border-radius: 4px;
            margin-bottom: 10px;
            .type {
              border: 1px solid #e41414;
              border-radius: 11px;
              font-size: 16px;
              font-weight: 300;
              color: #e41414;
              padding: 0px 14px;
            }
            .waveSeat {
              width: 14px;
              height: 17px;
              margin-right: 8px;
            }
            .wancheng-img {
              width: 14px;
              height: 14px;
              margin-right: 16px;
            }
            .title {
              font-size: 16px;
              font-weight: 400;
              color: #000000;
            }
            .look {
              color: #9d9d9d;
            }
          }
        }
      }
    }
    .commentContainer::-webkit-scrollbar {
      opacity: 0;
    }
    .commentContainer {
      height: 800px;
      overflow-y: scroll;
      .no-data {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        color: #7f7f7f;
      }
      .no-data-line {
        width: 249px;
        height: 1px;
        opacity: 0.17;
        border: 1px solid #979797;
      }
      .commentCard {
        margin-top: 25px;
        .el-divider--horizontal {
          margin-top: 0px;
          margin-bottom: 37px;
        }
        .infoCard {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .head {
            width: 60px;
            height: 60px;
            margin-right: 33px;
            border-radius: 100%;
          }
          .name {
            font-size: 16px;
            font-weight: 500;
            color: #2d2d2d;
          }
          .time {
            font-size: 14px;
            font-weight: 400;
            color: #a2a2a2;
          }
        }
        .muchdz {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .smolldz {
            width: 15px;
            height: 15px;
            margin-right: 6px;
          }
          .text {
            font-size: 12px;
            color: #747474;
          }
        }
      }
    }
  }
  .contianer-right {
    margin-top: 40px;
    width: 277px;
    .teacher {
      width: 100%;
      background: white;
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      }
      .text {
        padding: 12px 17px 6px 17px;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
      }
      .el-divider--horizontal {
        margin: auto;
        width: 260px;
      }
      .rankImg {
        width: 97px;
        height: 32px;
        margin-top: 14px;
      }
      .teacherInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        .head {
          width: 110px;
          height: 110px;
          border-radius: 100%;
          margin-bottom: 23px;
        }
        .fraction {
          font-size: 14px;
          color: #ff7500;
          margin-right: 10px;
        }
        /deep/.el-rate {
          height: auto;
          .el-rate__icon {
            margin-right: 0;
            font-size: 22px;
          }
        }
        .teacherName {
          font-size: 20px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 29px;
          margin-top: 15px;
        }
      }
      .number {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        padding: 0 17px;
      }
    }
  }
  .pos-fix {
    /deep/.el-dialog {
      border-radius: 10px;

      .el-dialog__body {
        padding: 0;
      }
      .el-dialog__header {
        padding: 0;
      }
      .rate-container {
        width: 648px;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .el-divider--horizontal {
          margin: auto;
        }
        .title {
          font-size: 18px;
          font-weight: 500;
          color: #2d2d2d;
          padding: 24px 0 14px 0;
        }
        .rate-type {
          padding: 0 120px;
          width: 100%;
        }
        .button {
          margin-top: 15px;
          width: 100px;
          background: linear-gradient(180deg, #ff3764, #ff2604);
          border-radius: 17px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          padding: 8px 0;
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>